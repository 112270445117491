import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  ChatClientContextProps,
  ChatClientProviderProps,
  Message,
  SenderType,
} from "models/chat.model";
import { ISource } from "models/question.model";

export const ChatClientContext = createContext<
  ChatClientContextProps | undefined
>(undefined);

const START_IDENTIFIER = "START: ANSWER STREAMING";
const END_IDENTIFIER = "END: ANSWER STREAMING";
const FINAL_IDENTIFIER = "FLAG: FINAL_ANSWER";
const DEFAULT_WAITING_FOR_RESPONSE_TEXT = "Waiting for response...";
const INITIAL_MESSAGE = "How can I help you today?";

export const ChatClientProvider: React.FC<ChatClientProviderProps> = ({
  url,
  children,
}) => {
  const websocketRef = useRef<WebSocket | null>(null);
  const currentGeneratedMessageRef = useRef<Message | null>(null);

  const [currentSources, setCurrentSources] = useState<ISource[]>([]);

  const [messages, setMessages] = useState<Message[]>([
    {
      id: new Date().toISOString(),
      sender: SenderType.BOT,
      content: INITIAL_MESSAGE,
      timestamp: new Date().toISOString(),
    },
  ]);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [chatEnded, setChatEnded] = useState<boolean>(false);
  const [waitingForResponseText, setShowWaitingForResponse] =
    useState<string>("");

  const onMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.data.includes(START_IDENTIFIER)) {
        setShowWaitingForResponse("");
        currentGeneratedMessageRef.current = {
          id: new Date().toISOString(),
          sender: SenderType.BOT,
          content: "",
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [
          ...prevMessages,
          currentGeneratedMessageRef.current as Message,
        ]);
      } else if (event.data.includes(END_IDENTIFIER)) {
        setMessageLoading(false);
        currentGeneratedMessageRef.current = null;
      } else if (event.data.includes(FINAL_IDENTIFIER)) {
        setChatEnded(true);
      } else if (currentGeneratedMessageRef.current) {
        currentGeneratedMessageRef.current.content += event.data;
        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message.id === currentGeneratedMessageRef.current?.id
              ? {
                  ...message,
                  content: currentGeneratedMessageRef.current!.content,
                }
              : message
          )
        );
      } else if (waitingForResponseText) {
        if (waitingForResponseText === DEFAULT_WAITING_FOR_RESPONSE_TEXT) {
          setShowWaitingForResponse(event.data);
        } else {
          setShowWaitingForResponse((prevWaitingForResponseText) =>
            prevWaitingForResponseText.concat("\n", event.data)
          );
        }
      } else {
        const messageParsed = JSON.parse(event.data);
        const { sources } = messageParsed;
        setCurrentSources(sources as unknown as ISource[]);
      }
    },
    [waitingForResponseText]
  );

  useEffect(() => {
    websocketRef.current = new WebSocket(url);
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [url]);

  useEffect(() => {
    if (websocketRef.current) {
      websocketRef.current.onmessage = onMessageHandler;
    }
  }, [onMessageHandler, messageLoading]);

  const sendMessage = (message: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: new Date().toISOString(),
        sender: SenderType.USER,
        content: message,
        timestamp: new Date().toISOString(),
      },
    ]);
    if (
      websocketRef.current &&
      websocketRef.current.readyState === WebSocket.OPEN
    ) {
      setShowWaitingForResponse(DEFAULT_WAITING_FOR_RESPONSE_TEXT);
      setMessageLoading(true);
      const messageParsed = {
        question: message,
        settings: {
          minScore: 0,
          maxSourceCount: 5,
          graphVersion: "dell",
        },
      };
      websocketRef.current.send(JSON.stringify(messageParsed));
    }
  };

  const startNewChat = () => {
    if (websocketRef.current) {
      websocketRef.current.close();
    }
    setMessages([
      {
        id: new Date().toISOString(),
        sender: SenderType.BOT,
        content: INITIAL_MESSAGE,
        timestamp: new Date().toISOString(),
      },
    ]);
    websocketRef.current = new WebSocket(url);
    websocketRef.current.onmessage = onMessageHandler;
    setMessageLoading(false);
    setChatEnded(false);
    setShowWaitingForResponse("");
  };

  const contextValue = useMemo(
    () => ({
      sendMessage,
      startNewChat,
      messages,
      messageLoading,
      chatEnded,
      waitingForResponseText,
      currentSources,
    }),
    [
      sendMessage,
      startNewChat,
      messages,
      messageLoading,
      chatEnded,
      waitingForResponseText,
      currentSources,
    ]
  );

  return (
    <ChatClientContext.Provider value={contextValue}>
      {children}
    </ChatClientContext.Provider>
  );
};
