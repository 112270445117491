import React, { ReactNode } from "react";

import { sidebarWidth } from "utils/constants";

import { useAppSelector } from "../../redux-store/hooks";

type SidebarLayoutProps = {
  className?: string;
  children: ReactNode;
};
export const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  children,
  className,
}) => {
  const { isSidebarOpen } = useAppSelector((state) => state.sharedUiSlice);

  const layoutStyles = {
    marginRight: isSidebarOpen ? sidebarWidth : 0,
    transition: "margin-right 0.4s ease",
  };

  return (
    <div className={className} style={layoutStyles}>
      {children}
    </div>
  );
};
