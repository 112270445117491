import { Button, Flex, Typography } from "antd";
import { ButtonType } from "antd/es/button";
import classnames from "classnames";
import { useMemo, useState } from "react";

import { Icon } from "icons";
import { IFetchDocumentAPIRequest } from "models/document.model";
import { ISource } from "models/question.model";
import { AnswerSourceDetails } from "models/shared";
import {
  fetchDocument,
  setAnswerSourceDetails,
} from "redux-store/document-slice";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { setIsSidebarOpen } from "redux-store/shared-ui-slice";
import { isSuccessfulStatus, handleApiError } from "utils/app.helpers";
import { SHOW_SETTINGS } from "utils/constants";

const { Paragraph, Text } = Typography;

const SourceItem = ({
  text,
  usedInAnswer,
  metadata: { contextStart, contextEnd, documentId, documentUrl },
  sourceId,
}: ISource) => {
  const dispatch = useAppDispatch();
  const { selectedSettings } = useAppSelector((state) => state.settingsSlice);
  const {
    answerSourceDetails: { selectedDocument, sourceId: selectedSourceId },
  } = useAppSelector((state) => state.documentSlice);

  const handleOnSourceClick = async (e: any) => {
    const isExpandOrCollapseButton =
      e.target.className.includes("ant-typography-expand") ||
      e.target.className.includes("ant-typography-collapse");
    if (isExpandOrCollapseButton) {
      return;
    }
    const initialAnswerSourceDetails = {
      sourceId,
      selectedDocument: { id: documentId },
      documentUrl,
    } as AnswerSourceDetails;

    dispatch(setAnswerSourceDetails(initialAnswerSourceDetails));
    dispatch(setIsSidebarOpen(true));

    let newSelectedDocument = { ...selectedDocument };

    if (selectedDocument?.id !== documentId) {
      const request: IFetchDocumentAPIRequest = {
        documentId,
        version: selectedSettings?.graphVersion,
      };
      const { payload } = await dispatch(fetchDocument(request));
      if (!isSuccessfulStatus(payload?.status)) {
        handleApiError(payload?.status, payload?.message);
      }

      newSelectedDocument = payload.data;
    }

    const answerSourceDetails = {
      ...initialAnswerSourceDetails,
      selectedDocument: { ...newSelectedDocument },
      highlightOffset: { contextStart, contextEnd },
    };
    dispatch(setAnswerSourceDetails(answerSourceDetails));
  };
  const isSelectedDocument = selectedDocument?.id === documentId;
  const isSelectedSource = selectedSourceId === sourceId;

  const listItemClassName = classnames({
    "list-item": true,
    "selected-document": isSelectedDocument,
    "active-source": isSelectedSource,
  });

  return (
    <Flex className={listItemClassName} onClick={(e) => handleOnSourceClick(e)}>
      {SHOW_SETTINGS && (
        <Flex justify="end">
          {usedInAnswer && (
            <Text className="used-source"> Used in answer </Text>
          )}
        </Flex>
      )}
      <Paragraph
        className="item-content"
        ellipsis={{
          rows: 2,
          expandable: "collapsible",
          symbol: (expanded) => (expanded ? "Show less" : "Show more"),
        }}
      >
        {text}
      </Paragraph>
    </Flex>
  );
};
type ViewAllButtonProps = {
  totalSourcesCount: number;
  showAllSources: boolean;
  handleViewAllClick: () => void;
  areAllSourcesUsedInAnswer: boolean;
};

const ViewAllButton = ({
  totalSourcesCount,
  showAllSources,
  handleViewAllClick,
  areAllSourcesUsedInAnswer,
}: ViewAllButtonProps) => {
  if (areAllSourcesUsedInAnswer) {
    return null;
  }

  const btnType: ButtonType = showAllSources ? "default" : "primary";
  const viewAllBtnClassName = classnames({
    "view-all-btn": true,
    "app-primary-btn": !showAllSources,
    "app-default-btn": showAllSources,
  });

  return (
    <Button
      onClick={handleViewAllClick}
      className={viewAllBtnClassName}
      type={btnType}
    >
      <Icon icon="ArrowDown" rotate={showAllSources ? 180 : 0} />
      {showAllSources ? "Show Less" : `View All ${totalSourcesCount} Sources`}
    </Button>
  );
};

type SourcesListProps = {
  sources: ISource[];
  onSourcesCountChange: (usedCount: number) => void;
};

const SourcesList = ({ sources, onSourcesCountChange }: SourcesListProps) => {
  const [showAllSources, setShowAllSources] = useState(false);

  const { displayedSources, areAllSourcesUsedInAnswer } = useMemo(() => {
    const used: ISource[] = [];
    const unused: ISource[] = [];

    sources?.forEach((source) => {
      if (source.usedInAnswer) {
        used.push(source);
      } else {
        unused.push(source);
      }
    });

    onSourcesCountChange(used.length);

    return {
      displayedSources: showAllSources ? [...used, ...unused] : used,
      areAllSourcesUsedInAnswer: unused.length === 0,
    };
  }, [sources, showAllSources]);

  const handleViewAllClick = () => {
    setShowAllSources((prevShowAllSources) => !prevShowAllSources);
  };

  return (
    <Flex className="listing-wrapper" vertical>
      {displayedSources.map((source, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SourceItem key={`${source.sourceId}_${index}`} {...source} />
      ))}
      {SHOW_SETTINGS && (
        <ViewAllButton
          showAllSources={showAllSources}
          handleViewAllClick={handleViewAllClick}
          areAllSourcesUsedInAnswer={areAllSourcesUsedInAnswer}
          totalSourcesCount={sources.length}
        />
      )}
    </Flex>
  );
};

export default SourcesList;
