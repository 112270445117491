import "./QueryForm.scss";
import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import classNames from "classnames";
import React, { useState, useEffect } from "react";

import { useChatClient } from "hooks/useChatClient";
import { Icon } from "icons";

const QueryForm = () => {
  const [query, setQuery] = useState("");

  const chatClient = useChatClient();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    chatClient.sendMessage(query);
    setQuery("");
  };

  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (chatClient.chatEnded || chatClient.messageLoading) setIsFocused(false);
  }, [chatClient.chatEnded, chatClient.messageLoading]);

  const getPlaceholder = () => {
    if (chatClient.chatEnded) {
      return "Chat Ended";
    }
    if (chatClient.messageLoading) {
      return "Please Wait...";
    }
    return "Type your message...";
  };
  const questionClassName = classNames({
    question: true,
    focus: isFocused,
    disabled: chatClient.chatEnded || chatClient.messageLoading,
  });

  return (
    <Form className="question-form" onFinish={handleSubmit}>
      <Form.Item
        name="question"
        className={questionClassName}
        style={{ flex: 1 }}
      >
        <TextArea
          className="query-input"
          value={query}
          onChange={handleInputChange}
          placeholder={getPlaceholder()}
          onPressEnter={(e) => {
            if (e.shiftKey) {
              return;
            }
            e.preventDefault();
            if (query.length > 0) {
              handleSubmit(e);
            }
          }}
          disabled={chatClient.chatEnded || chatClient.messageLoading}
          autoSize={{ minRows: 1, maxRows: 5 }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Button
          className="query-button"
          type="primary"
          disabled={
            query.length === 0 ||
            chatClient.chatEnded ||
            chatClient.messageLoading
          }
          onClick={handleSubmit}
        >
          <Icon icon="ArrowRight" className="question-send-icon" />
        </Button>
      </Form.Item>
    </Form>
  );
};

export default QueryForm;
