import "./Chat.scss";

import { Button, Popover, Typography, message as antdMessageAlert } from "antd";
import classNames from "classnames";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import { useChatClient } from "hooks/useChatClient";
import { Icon } from "icons";
import { SenderType } from "models/chat.model";

import ChatActions from "./ChatActions";
import SourcesList from "./SourcesList";

const { Paragraph } = Typography;
const COPY_MSG = "Copied to clipboard!";

type ChatBubbleProps = {
  children: React.ReactNode;
  sourcesContent: React.ReactNode;
  key: string;
  isSystemMessage?: boolean;
  isLastMessage?: boolean;
  onCopy: () => void;
  onSourcesClick?: () => void;
  showSourcesToggle: boolean;
};
const chatBubbleContainerClassName = (isSystemMessage: boolean) =>
  classNames("chat-bubble-container", {
    "chat-bubble-system-message": isSystemMessage,
    "chat-bubble-user-message": !isSystemMessage,
  });

const ChatBubble = ({
  children,
  key,
  isSystemMessage = false,
  isLastMessage,
  onCopy,
  onSourcesClick,
  sourcesContent,
  showSourcesToggle,
}: ChatBubbleProps) => (
  <div className={chatBubbleContainerClassName(isSystemMessage)}>
    <div key={key} className="chat-bubble">
      <div className="chat-bubble-content">
        {isSystemMessage && (
          <Icon icon="LogoSprite" width="calc(1.5rem + 0.7vw)" />
        )}
        {children}
      </div>
      {showSourcesToggle && sourcesContent && (
        <Popover trigger="click" content={sourcesContent}>
          <Button
            type="primary"
            onClick={onSourcesClick}
            className="chat-bubble-sources-btn"
          >
            Sources
          </Button>
        </Popover>
      )}
    </div>

    <ChatActions isLastMessage={!!isLastMessage} onCopy={onCopy} />
  </div>
);

const WaitingForResponseLoader = ({ content }: { content: string }) => (
  <div className="waiting-for-response-loader">
    <Icon
      icon="LogoSprite"
      width="calc(1.5rem + 0.7vw)"
      className="waiting-for-response-loader-icon"
    />
    <div className="waiting-for-response-loader-content">
      {content.split("\n").map((message) => (
        <p key={message}>{message}</p>
      ))}
    </div>
  </div>
);

const Chat = () => {
  const chat = useChatClient();
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [chat.messages]);
  const renderSourcesList = (isLastMessage: boolean) => {
    if (!isLastMessage) return null;
    return chat.currentSources ? (
      <div className="source-list-container listing-card">
        <SourcesList
          sources={chat.currentSources}
          onSourcesCountChange={() => {}}
        />
      </div>
    ) : (
      "No sources available"
    );
  };

  return (
    <div className="chat-panel">
      {chat.messages.map((message, index) => (
        <ChatBubble
          key={message.id}
          isSystemMessage={message.sender === SenderType.BOT}
          isLastMessage={index === chat.messages.length - 1 && chat.chatEnded}
          onCopy={() => {
            const text = message.content.replace(/[#_*~`>]/g, "");
            navigator.clipboard.writeText(text);
            antdMessageAlert.success(COPY_MSG);
          }}
          showSourcesToggle={!!chat.currentSources?.length}
          sourcesContent={renderSourcesList(
            index === chat.messages.length - 1 && chat.chatEnded
          )}
        >
          <Paragraph>
            <ReactMarkdown
              components={{
                // eslint-disable-next-line react/no-unstable-nested-components
                h3: ({ node, ...props }) => (
                  <p
                    style={{ fontSize: "1rem", fontWeight: "bolder" }}
                    {...props}
                  />
                ),
              }}
            >
              {message.content}
            </ReactMarkdown>
          </Paragraph>
        </ChatBubble>
      ))}
      {chat.waitingForResponseText && (
        <WaitingForResponseLoader content={chat.waitingForResponseText} />
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default Chat;
