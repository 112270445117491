import { configureStore } from "@reduxjs/toolkit";

import documentSlice from "./document-slice";
import logsSlice from "./logs-slice";
import questionSlice from "./question-slice";
import settingsSlice from "./settings-slice";
import sharedUiSlice from "./shared-ui-slice";

export const store = configureStore({
  reducer: {
    logsSlice,
    questionSlice,
    settingsSlice,
    sharedUiSlice,
    documentSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
