import React from "react";

import { ISource } from "./question.model";

export enum SenderType {
  BOT = "BOT",
  USER = "USER",
}

export interface Message {
  id: string;
  sender: SenderType;
  content: string;
  timestamp: string;
}

export interface ChatClientContextProps {
  sendMessage: (message: string) => void;
  startNewChat: () => void;
  messages: Message[];
  messageLoading: boolean;
  chatEnded: boolean;
  waitingForResponseText: string;
  currentSources: ISource[];

}

export interface ChatClientProviderProps {
  url: string;
  children: React.ReactNode;
}
