import { LinkOutlined } from "@ant-design/icons";
import { Drawer, Flex } from "antd";
import React from "react";

import "./Sidebar.scss";
import { sidebarWidth } from "utils/constants";

interface SidebarProps {
  children: React.ReactNode;
  url?: string;
  onClose: () => void;
  open?: boolean;
  loading?: boolean;
}

const SidebarURL = ({ url }: { url: string }) => (
  <Flex className="sidebar-url-wrapper">
    <LinkOutlined />
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="app-link"
    >
      {url}
    </a>
  </Flex>
);

export const Sidebar = ({
  children,
  url,
  open,
  onClose,
  loading,
}: SidebarProps) => (
  <Drawer
    placement="right"
    onClose={onClose}
    open={open}
    mask={false}
    rootClassName="app-sidebar"
    getContainer={false}
    width={sidebarWidth}
    keyboard
    loading={loading}
  >
    {url && <SidebarURL url={url} />}
    {children}
  </Drawer>
);
