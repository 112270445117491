import { PayloadAction } from "@reduxjs/toolkit";
import { ReactNode } from "react";

import { IFetchDocumentAPIResponse } from "./document.model";
import { LogSourceItem } from "./logs.model";

export type Status = "idle" | "loading" | "succeeded" | "failed";

export interface InterceptedResponse {
  data?: any;
  error?: boolean;
  errors?: string[];
  message?: string;
  status: number;
}

export type AppDispatchType = Promise<PayloadAction<InterceptedResponse>>;

export enum ErrorType {
  Client,
  Server,
  Unknown,
}

export interface IModal {
  handlePrimaryBtnClick: (params?: unknown) => void;
  handleSecondaryBtnClick: () => void;
  isPrimaryBtnLoading?: boolean;
  modalBody: ReactNode;
  onCancel: () => void;
  primaryBtnText: string | ReactNode;
  secondaryBtnText: string;
  visible: boolean;
}

export interface IPagination {
  pageSize: number;
  pageIndex: number;
}

export interface SourceDetails {
  selectedDocument: IFetchDocumentAPIResponse | null;
  documentUrl: string;
}

export interface AnswerSourceDetails extends SourceDetails {
  sourceId: string;
  highlightOffset: { contextStart: number; contextEnd: number } | null;
}

export interface LogSourceDetails extends SourceDetails {
  source: LogSourceItem;
}
