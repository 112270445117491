import { createSlice } from "@reduxjs/toolkit";

interface SharedUiState {
  isSidebarOpen: boolean;
}

const initialState: SharedUiState = {
  isSidebarOpen: false,
};

const sharedUiSlice = createSlice({
  name: "shared-ui",
  initialState,
  reducers: {
    setIsSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { setIsSidebarOpen } = sharedUiSlice.actions;
export default sharedUiSlice.reducer;
