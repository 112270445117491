import { Button, Flex, Space, Spin, message } from "antd";
import classNames from "classnames";
import { useState } from "react";

import "./Chat.scss";
import { Icon } from "icons";
import { useAppSelector } from "redux-store/hooks";

const FEEDBACK_FIRST_SUBMIT_MSG = "Thanks for your feedback!";
const FEEDBACK_UPDATE_MSG = "Feedback updated successfully!";

type Props = {
  isLastMessage: boolean;
  onCopy: () => void;
};

const ChatActions = ({ isLastMessage, onCopy }: Props) => {
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { isVerifyingAnswer } = useAppSelector((state) => state.questionSlice);
  const feedbackBtnYesClassName = classNames("feedback-btn", {
    "feedback-btn-yes": true,
    selected: isCorrectAnswer === true,
  });

  const feedbackBtnNoClassName = classNames("feedback-btn", {
    "feedback-btn-no": true,
    selected: isCorrectAnswer === false,
  });

  const onVerifyBtnClick = async (isCorrect: boolean) => {
    const isSameFeedback = isCorrectAnswer === isCorrect;
    if (isSameFeedback) {
      return;
    }
    setIsCorrectAnswer(isCorrect);
    if (hasSubmitted) {
      message.success(FEEDBACK_UPDATE_MSG);
    } else {
      message.success(FEEDBACK_FIRST_SUBMIT_MSG);
      setHasSubmitted(true);
    }
  };

  const isLoading = isVerifyingAnswer === "loading";

  return (
    <Space className="chat-bubble-action-wrapper">
      <Flex className="chat-bubble-action">
        {isLastMessage && (
          <>
            <Spin size="small" spinning={isLoading} />
            <Button
              type="link"
              className={feedbackBtnYesClassName}
              icon={<Icon icon="Laugh" width={14} />}
              onClick={() => onVerifyBtnClick(true)}
              disabled={isLoading}
            >
              Helpful
            </Button>
            <Button
              type="link"
              className={feedbackBtnNoClassName}
              icon={<Icon icon="Frown" width={14} />}
              onClick={() => onVerifyBtnClick(false)}
              disabled={isLoading}
            >
              Not Helpful
            </Button>
          </>
        )}
        <Button type="link" className="copy-btn" onClick={onCopy}>
          <Icon icon="Copy" width={14} />
        </Button>
      </Flex>
    </Space>
  );
};

export default ChatActions;
