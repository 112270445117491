import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchDocumentAPI } from "api/document";
import { IFetchDocumentAPIRequest } from "models/document.model";
import { AnswerSourceDetails, LogSourceDetails, Status } from "models/shared";

interface DocumentState {
  isFetchingDocument: Status;
  answerSourceDetails: AnswerSourceDetails;
  logSourceDetails: LogSourceDetails;
}

const initialState: DocumentState = {
  isFetchingDocument: "idle",
  answerSourceDetails: {
    selectedDocument: null,
    highlightOffset: null,
    sourceId: "",
    documentUrl: "",
  } as AnswerSourceDetails,
  logSourceDetails: {
    selectedDocument: null,
    source: {},
    documentUrl: "",
  } as LogSourceDetails,
};

export const fetchDocument = createAsyncThunk(
  "document/fetchDocument",
  async (request: IFetchDocumentAPIRequest) => {
    const response = await fetchDocumentAPI(request);
    return response;
  }
);
const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    setAnswerSourceDetails: (state: DocumentState, action) => {
      state.answerSourceDetails = action.payload;
    },
    setLogSourceDetails: (state: DocumentState, action) => {
      state.logSourceDetails = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocument.pending, (state: DocumentState) => {
        state.isFetchingDocument = "loading";
      })
      .addCase(fetchDocument.fulfilled, (state: DocumentState) => {
        state.isFetchingDocument = "succeeded";
      })
      .addCase(fetchDocument.rejected, (state: DocumentState) => {
        state.isFetchingDocument = "failed";
      });
  },
});

export const { setAnswerSourceDetails, setLogSourceDetails } =
  documentSlice.actions;

export default documentSlice.reducer;
