import { message } from "antd";

import { ErrorType } from "models/shared";

import { DEFAULT_ERROR_MSG } from "./constants";

export const isSuccessfulStatus = (status: number): boolean =>
  status >= 200 && status < 300;

export const determineErrorType = (statusCode: number): ErrorType => {
  if (statusCode >= 400 && statusCode < 500) {
    return ErrorType.Client;
  }
  if (statusCode >= 500 && statusCode < 600) {
    return ErrorType.Server;
  }
  return ErrorType.Unknown;
};

export const handleApiError = (
  status: number,
  clientErrorMsg: string,
  customErrorMsg?: string
) => {
  const errorType = determineErrorType(status);
  switch (errorType) {
    case ErrorType.Client:
      if (clientErrorMsg) {
        message.error(clientErrorMsg);
        return;
      }
      message.error(customErrorMsg || DEFAULT_ERROR_MSG);
      break;
    default:
      message.error(customErrorMsg || DEFAULT_ERROR_MSG);
      break;
  }
};

export const formatLogsTimestamp = (value: number) =>
  new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "2-digit",
    year: "numeric",
    timeZone: "UTC",
  }).format(new Date(value * 1000));

// remove null or undefined values from object
export const removeNullUndefined = (obj: any) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const getParamsFromURL = (paramName: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  const param = queryParams.get(paramName);
  if (param) {
    return param.split(",").map((item) => decodeURIComponent(item));
  }
  return [];
};

export const updateURLParams = (
  url: string,
  paramName: string,
  params: string[]
) => {
  const queryParams = new URLSearchParams();
  queryParams.set(paramName, params.join(","));
  window.history.pushState({}, "", `${url}${queryParams.toString()}`);
};

export const toTimestamp = (dateString: string) =>
  Math.floor(new Date(dateString).getTime() / 1000);

export const toEndOfDayTimestamp = (dateString: string) => {
  const endDate = new Date(dateString);
  endDate.setUTCHours(23, 59, 59, 999);
  return Math.floor(endDate.getTime() / 1000);
};
