export const lightColorPalette = {
  white: {
    100: "#FBFBFD", // Lightest
    200: "#fff", // Default White
    300: "#f9f9f9", // Off White
  },
  grey: {
    50: "#F5F7FA", // Lightest Grey
    100: "#EBECFC", // Lighter Grey
    150: "#DDE8F2", // Light Grey
    200: "#f5f5f5", // Subtle Grey
    250: "#e5e5e5", // Muted Grey
    300: "#A0A0A0", // Medium Grey
    350: "#828282", // Dark Grey
    400: "#d0d0d0", // Border Grey
    500: "#e0e0e0", // Inactive Grey
    900: "#393956", // Darkest Grey
  },
  purple: {
    100: "#6a6a9f33", // Transparent Light Purple (20% opacity of 300)
    300: "#6A6A9F", // Primary Purple
    350: "#6D76DC", // Accent Purple
    400: "#8E8EBC", // Muted Purple
    500: "#4251ED", // Default Purple
    700: "#2D369F", // Hover Purple
  },
  red: {
    400: "#FF4F4F", // Error Red
  },
  green: {
    500: "#0EBC15", // Success Green
  },
  blue: {
    500: "#007bff", // Primary Blue
  },
  yellow: {
    300: "#FDFF92", // Highlight Yellow
  },
};

export const lightPalette = {
  layout: {
    headerBg: lightColorPalette.white[200],
    contentBg: lightColorPalette.white[100],
  },
  app: {
    primaryText: lightColorPalette.grey[900],
    primaryColor: lightColorPalette.purple[500],
    accentColor: lightColorPalette.purple[300],
    btnAccentColor: lightColorPalette.purple[350],
    secondaryText: lightColorPalette.purple[400],
    dangerText: lightColorPalette.red[400],
    hoverColor: lightColorPalette.purple[700],
    placeholderColor: lightColorPalette.grey[350],
    cardItemBg: lightColorPalette.grey[50],
    inactiveDefaultBtnBorder: lightColorPalette.purple[300],
    selectBorder: lightColorPalette.purple[400],
    selectOptionBorder: lightColorPalette.purple[300],
    selectOptionActive: lightColorPalette.purple[100],
    modalFooterBorder: lightColorPalette.grey[100],
    filterBtnBorder: lightColorPalette.purple[400],
    colorTextDisabled: lightColorPalette.grey[350],
    cardBorder: lightColorPalette.grey[100],
    selectedCardBg: lightColorPalette.grey[150],
    highlightColor: lightColorPalette.yellow[300],
  },
  chat: {
    chatPanelBg: lightColorPalette.grey[200],
    chatBubbleUserBg: lightColorPalette.white[200],
    formInputBg: lightColorPalette.white[200],
    formInputFocus: lightColorPalette.blue[500],
    formInputDisabled: lightColorPalette.grey[200],
    copyBtnStroke: lightColorPalette.grey[300],
    copyBtnHoverStroke: lightColorPalette.grey[900],
    questionSendBtnStroke: lightColorPalette.white[200],
    newChatBtnStroke: lightColorPalette.grey[900],
    newChatBtnDisabledStroke: lightColorPalette.grey[500],
    newChatBtnHoverBg: lightColorPalette.grey[250],
    sourcesBtnBg: lightColorPalette.white[300],
    sourcesBtnHoverBg: lightColorPalette.grey[250],
    sourcesBtnBorder: lightColorPalette.grey[500],
    sourcesBtnHoverBorder: lightColorPalette.grey[400],
  },
};
