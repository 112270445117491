import { IFetchDocumentAPIRequest } from "models/document.model";

import { axiosConfig } from "./axios";

export const fetchDocumentAPI = async ({
  version,
  documentId,
}: IFetchDocumentAPIRequest) => {
  const url = `/documents?version=${version}&documentId=${documentId}`;
  const response = await axiosConfig("GET", url);
  return response;
};
