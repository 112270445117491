import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { askQuestionAPI, verifyAnswerAPI } from "api/question";
import {
  IAskRequest,
  IAskResponse,
  IVerifyAnswerRequest,
} from "models/question.model";
import { InterceptedResponse, Status } from "models/shared";

const QUESTION_RESPONSE_INITIAL_VALUE = {
  question: "",
  answer: "",
  sources: [],
  subgraph: [],
  stats: { totalTimeMs: 0, totalSourceCount: 0 },
  logId: "",
};

interface QuestionAnswerState {
  questionResponse: IAskResponse;
  isAskingQuestion: Status;
  shouldResetQuestionAnswer: boolean;
  isVerifyingAnswer: Status;
}

const initialState = {
  isAskingQuestion: "idle",
  questionResponse: { ...QUESTION_RESPONSE_INITIAL_VALUE },
  shouldResetQuestionAnswer: false,
  isVerifyingAnswer: "idle",
} as QuestionAnswerState;

export const askQuestion = createAsyncThunk(
  "question/askQuestion",
  async (request: IAskRequest) => {
    const response = await askQuestionAPI(request);
    return response;
  }
);

export const verifyAnswer = createAsyncThunk(
  "question/verifyAnswer",
  async (request: IVerifyAnswerRequest) => {
    const response = await verifyAnswerAPI(request);
    return response;
  }
);
export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    resetQuestionAnswer: (state) => {
      state.shouldResetQuestionAnswer = true;
      state.questionResponse = { ...QUESTION_RESPONSE_INITIAL_VALUE };
    },
    setShouldResetQuestionAnswer: (state, action) => {
      state.shouldResetQuestionAnswer = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(askQuestion.pending, (state: QuestionAnswerState) => {
        state.isAskingQuestion = "loading";
      })
      .addCase(
        askQuestion.fulfilled,
        (state: QuestionAnswerState, { payload }) => {
          state.isAskingQuestion = "succeeded";
          state.questionResponse = (payload as InterceptedResponse).data;
        }
      )
      .addCase(askQuestion.rejected, (state: QuestionAnswerState) => {
        state.isAskingQuestion = "failed";
      })
      .addCase(verifyAnswer.pending, (state: QuestionAnswerState) => {
        state.isVerifyingAnswer = "loading";
      })
      .addCase(verifyAnswer.fulfilled, (state: QuestionAnswerState) => {
        state.isVerifyingAnswer = "succeeded";
      })
      .addCase(verifyAnswer.rejected, (state: QuestionAnswerState) => {
        state.isVerifyingAnswer = "failed";
      });
  },
});

export const { resetQuestionAnswer, setShouldResetQuestionAnswer } =
  questionSlice.actions;

export default questionSlice.reducer;
