import React, { useRef, useEffect } from "react";

import { lightPalette } from "themes/light-palette";

interface HighlightProps {
  text: string;
  start: number;
  end: number;
}

const Highlight: React.FC<HighlightProps> = ({ text, start, end }) => {
  const highlightRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (highlightRef.current) {
      highlightRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [start, end]);

  const beforeHighlight = text?.substring(0, start);
  const highlightedText = text?.substring(start, end);
  const afterHighlight = text?.substring(end);

  return (
    <span>
      {beforeHighlight}
      <span
        ref={highlightRef}
        style={{ backgroundColor: lightPalette.app.highlightColor }}
      >
        {highlightedText}
      </span>
      {afterHighlight}
    </span>
  );
};

export default Highlight;
