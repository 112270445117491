import { Button, Divider } from "antd";

import Chat from "components/Chat";
import { ChatClientProvider } from "components/Chat/ChatClient";
import QueryForm from "components/Chat/QueryForm/QueryForm";
import { useChatClient } from "hooks/useChatClient";
import { Icon } from "icons";
import { AnswerSourceDetails } from "models/shared";
import { setAnswerSourceDetails } from "redux-store/document-slice";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { setIsSidebarOpen } from "redux-store/shared-ui-slice";
import Highlight from "shared/Sidebar/Highlight";
import { Sidebar } from "shared/Sidebar/Sidebar";
import { SidebarLayout } from "shared/Sidebar/SidebarLayout";
import "./ChatBot.scss";
import { askWebSocketUrl } from "utils/constants";

const ChatHeader = () => {
  const chat = useChatClient();

  const newChat = () => {
    if (chat.messages.length > 1) {
      chat.startNewChat();
    }
  };
  return (
    <div className="chat-header">
      <Button
        type="primary"
        onClick={newChat}
        disabled={chat.messages.length <= 1}
        className="new-chat-button"
      >
        <Icon icon="NewChat" className="new-chat-icon" />
        New Chat
      </Button>
    </div>
  );
};

const ChatBot = () => {
  const { isSidebarOpen } = useAppSelector((state) => state.sharedUiSlice);
  const {
    answerSourceDetails: { selectedDocument, highlightOffset, documentUrl },
    isFetchingDocument,
  } = useAppSelector((state) => state.documentSlice);
  const dispatch = useAppDispatch();

  const onSidebarClose = () => {
    dispatch(setIsSidebarOpen(false));
    dispatch(setAnswerSourceDetails({} as AnswerSourceDetails));
  };

  return (
    <ChatClientProvider url={askWebSocketUrl}>
      <SidebarLayout className="chat-bot-container">
        <ChatHeader />
        <Chat />
        <Divider style={{ margin: "16px 0" }} />
        <QueryForm />
      </SidebarLayout>

      <Sidebar
        open={isSidebarOpen}
        onClose={onSidebarClose}
        loading={isFetchingDocument === "loading"}
        url={documentUrl}
      >
        {selectedDocument && (
          <Highlight
            text={selectedDocument.content}
            start={highlightOffset?.contextStart || 0}
            end={highlightOffset?.contextEnd || 0}
          />
        )}
      </Sidebar>
    </ChatClientProvider>
  );
};

export default ChatBot;
