import { useContext } from "react";

import { ChatClientContext } from "components/Chat/ChatClient";
import { ChatClientContextProps } from "models/chat.model";

export const useChatClient = (): ChatClientContextProps => {
  const context = useContext(ChatClientContext);
  if (!context) {
    throw new Error("useChatClient must be used within a ChatClientProvider");
  }
  return context;
};
